import React, { useState, useEffect, useRef } from 'react';

const AnimatedSVG: React.FC = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [numRows, setNumRows] = useState<number>(0);
  const [numCols, setNumCols] = useState<number>(0);
  const gridSize = 15; // size of each grid cell
  const fontSize = 12; // font size for characters
  const [rows, setRows] = useState<string[]>([]);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.offsetWidth;
        const parentHeight = parentRef.current.offsetHeight;

        // Calculate number of columns and rows based on the parent dimensions
        const calculatedNumCols = Math.floor(parentWidth / fontSize);
        const calculatedNumRows = Math.floor(parentHeight / gridSize);

        setNumCols(calculatedNumCols);
        setNumRows(calculatedNumRows);
      }
    };

    // Call handleResize on initial load and whenever the window is resized
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Initialize rows with circular pattern of X around the center
    const newRows: string[] = [];
    for (let i = 0; i < numRows; i++) {
      const rowArray: string[] = [];
      for (let j = 0; j < numCols; j++) {
        // Calculate distance from center
        const distance = Math.sqrt((i - Math.floor(numRows / 2)) ** 2 + (j - Math.floor(numCols / 2)) ** 2);

        // Set character based on circular pattern
        if (distance >= Math.floor(numRows / 2) - 10 && distance <= Math.floor(numRows / 2) + 5) {
          rowArray.push('O');
        } else {
          rowArray.push(Math.random() > 0.7 ? 'X' : 'x');
        }
      }
      newRows.push(rowArray.join(''));
    }
    setRows(newRows);

    // Start interval to update rows with new random strings
    const id = setInterval(() => {
      const updatedRows = newRows.map((row, rowIndex) => {
        const newRowArray = row.split('').map((char, colIndex) => {
          // Calculate distance from center
          const distance = Math.sqrt((rowIndex - Math.floor(numRows / 2)) ** 2 + (colIndex + (numCols/ 7.5) - Math.floor(numCols / 2)) ** 2);

          // Set character based on circular pattern
          if (distance >= Math.floor(numRows / 2) - (numCols / 50) && distance <= Math.floor(numRows / 2) + (numCols / 20)) {
            return Math.random() > 0.2 ? 'X' : 'x';
          } else {
            return Math.random() > 0.2 ? ' ' : 'x';
          }
        });
        return newRowArray.join('');
      });
      setRows(updatedRows);
    }, 100); // Change every 100 milliseconds

    return () => {
      if (id) clearInterval(id);
    };
  }, [numCols, numRows]);

  return (
    <div ref={parentRef} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <svg
        id="bannerSVG"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox={`0 0 ${numCols * fontSize} ${numRows * gridSize}`}
        style={{ backgroundColor: 'black', width: '100%', height: '100%' }}
      >
        {/* Rows */}
        {rows.map((row, index) => (
          <text
            key={index}
            x={0}
            y={(index + 1) * gridSize}
            fill="#888888"
            dy={0}
            style={{
              fontSize: `${fontSize}px`,
              letterSpacing: 11,
              fontWeight: 'bold',
              lineHeight: 0,
              margin: 0
            }}
            xmlSpace="preserve"
          >
            {row}
          </text>
        ))}

        {/* Heading */}
        <image
          xlinkHref="/icons/icon.png"
          x={(numCols * fontSize) / 2 - (numRows + 10)} // Adjust the positioning as needed
          y={(numRows * gridSize) / 2 - (numRows * 4.5)} // Adjust the positioning as needed
          width={`${numCols}px`}
          height={`${numRows * 4}px`}
        />
        <text
          x={(numCols * fontSize) / 2 + (numRows/2.5)}
          y={(numRows * gridSize) / 2 + (numRows * 1)}
          fill="#ffffff"
          style={{
            fontSize: `${numRows*3}px`,
            dominantBaseline: 'middle',
            fontFamily: "Audiowide",
            textAnchor: 'middle',
            zIndex: 1,
          }}
        >
          XCoin
        </text>
        <text
          x={(numCols * fontSize) / 2 + (numRows/2.5)}
          y={(numRows * gridSize) / 2 + (numRows * 3)}
          fill="#ffffff"
          style={{
            fontSize: `${numRows / 1.5}px`,
            dominantBaseline: 'middle',
            fontFamily: "Audiowide",
            textAnchor: 'middle',
            zIndex: 1,
          }}
        >
          In X We Trust.
        </text>
      </svg>
    </div>
  );
};

export default AnimatedSVG;
