import "./App.css";
import AboutSection from "./components/About";
import AnimatedSVG from "./components/AnimatedSVG";
import CareerSection from "./components/Career";
import ContactUsSection from "./components/Contact";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";

function App() {
  return (
    <div className="bg-black">
      <div
        className='App'
        style={{ height: "90vh", width: "100%", margin: "auto" }}>
        <AnimatedSVG />
      </div>
      <NavBar />
      <AboutSection />
      {/* <CareerSection /> */}
      <ContactUsSection />
      <Footer />
    </div>
  );
}

export default App;
