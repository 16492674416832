import React from 'react';

const About: React.FC = () => {
  return (
    <section className="py-20">
      <div className="container mx-auto px-8 md:px-16" style={{fontFamily: "Audiowide"}}>
        <div className="flex flex-wrap -mx-4">
          <div className="w-full md:w-1/2 px-4 mb-10 md:mb-0">
            <h2 className="text-4xl font-bold mb-6 text-gray-100">Who We Are</h2>
            <p className="text-lg text-gray-300 leading-relaxed">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              vehicula ante ac nisi malesuada, id vestibulum eros convallis.
              Integer tincidunt aliquet nulla, id placerat leo volutpat eget.
              Donec vestibulum suscipit lectus non viverra.
            </p>
          </div>
          <div className="w-full md:w-1/2 px-4">
            <h2 className="text-4xl font-bold mb-6 text-gray-100">Our Mission</h2>
            <p className="text-lg text-gray-300 leading-relaxed">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              vehicula ante ac nisi malesuada, id vestibulum eros convallis.
              Integer tincidunt aliquet nulla, id placerat leo volutpat eget.
              Donec vestibulum suscipit lectus non viverra.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
