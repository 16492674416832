import React from 'react';

const ContactUsSection: React.FC = () => {
  return (
    <section className="py-20 text-gray-100" style={{fontFamily: "Audiowide"}}>
      <div className="container mx-auto px-8 md:px-16">
        <h2 className="text-4xl font-bold mb-10 text-center">Get in Touch</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 bg-gray-900 rounded-lg shadow-md text-gray-800">
            <h3 className="text-2xl font-semibold mb-4 text-gray-200">Our Office</h3>
            <p className="text-lg mb-4 text-gray-100">1234 Example Street</p>
            <p className="text-lg mb-4 text-gray-100">City, State ZIP</p>
            <p className="text-lg mb-4 text-gray-100">Country</p>
          </div>
          <div className="p-6 bg-gray-900 rounded-lg shadow-md text-gray-800">
            <h3 className="text-2xl font-semibold mb-4 text-gray-200">Contact Information</h3>
            <p className="text-lg mb-4 text-gray-100">Phone: +1 (123) 456-7890</p>
            <p className="text-lg mb-4 text-gray-100">Email: info@example.com</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
