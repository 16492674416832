import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-8">
      <div className="container mx-auto px-8">
        <div className="flex justify-between items-center">
          <div>
            <p>&copy; {new Date().getFullYear()} Xcoin. All rights reserved.</p>
          </div>
          <div>
            <a href="/privacy" className="mr-4">Privacy Policy</a>
            <a href="/terms" className="mr-4">Terms of Service</a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
