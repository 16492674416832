import React from 'react';

const NavBar: React.FC = () => {
  return (
    <nav className="p-4 flex justify-between">
      {/* Left corner buttons */}
      <div className="flex items-center space-x-4">
        {/* Documentation button */}
        <button className="flex items-center bg-purple-600 hover:bg-purple-700 text-white font-semibold py-2 px-4 rounded-full">
          <svg
            className="h-4 w-4 mr-1 text-white"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M3 3h9v9H3V3zm12 0h6v6h-6V3zm-12 12h9v9H3v-9zm12 0h6v6h-6v-6z" />
          </svg>
          Documentation
        </button>
        {/* Showcase button */}
        <button className="flex items-center bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-4 rounded-full">
          <svg
            className="h-4 w-4 mr-1 text-white"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M3 3h18v12H3zM3 3l9 9 9-9" />
          </svg>
          Showcase
        </button>
      </div>

      {/* Right corner buttons */}
      <div className="flex items-center space-x-4">
        {/* Join the Beta button */}
        <button className="flex items-center bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-full">
          <svg
            className="h-4 w-4 mr-1 text-white"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M9 11a2 2 0 11-4 0 2 2 0 014 0zM9 3a6 6 0 00-6 6v2a6 6 0 006 6h6a6 6 0 006-6V9a6 6 0 00-6-6h-1" />
          </svg>
          Join the Beta
        </button>
        {/* Sign In button */}
        <button className="flex items-center bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-2 px-4 rounded-full">
          <svg
            className="h-4 w-4 mr-1 text-white"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M19 9l-7 7-7-7" />
          </svg>
          Sign In
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
